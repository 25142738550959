<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ $t("mapping.mappingCreate") }}
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'projectMappings' })"
        >
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">{{ $t("mapping.mappingCreate") }}:</h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingLabel") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.mapping.label.$model"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': $v.mapping.label.$error,
              'is-valid': $v.mapping.label.$dirty && !$v.mapping.label.$error
            }"
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingDescription") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <textarea v-model="mapping.description" class="form-control" />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingActive") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <span class="switch">
            <label>
              <input v-model="$v.mapping.active.$model" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingDataStructureSource") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            v-model="$v.mapping.source_data_structure_id.$model"
            :items="dataStructures"
            :item-text="ds => `${ds.label} - v.${ds.version}`"
            item-value="id"
            :menu-props="{ offsetY: true }"
            class="form-control"
            :class="{
              'is-invalid': $v.mapping.source_data_structure_id.$error,
              'is-valid':
                $v.mapping.source_data_structure_id.$dirty &&
                !$v.mapping.source_data_structure_id.$error
            }"
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingDataStructureTarget") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            v-model="$v.mapping.target_data_structure_id.$model"
            :items="dataStructures"
            :item-text="ds => `${ds.label} - v.${ds.version}`"
            item-value="id"
            :menu-props="{ offsetY: true }"
            class="form-control"
            :class="{
              'is-invalid': $v.mapping.target_data_structure_id.$error,
              'is-valid':
                $v.mapping.target_data_structure_id.$dirty &&
                !$v.mapping.target_data_structure_id.$error
            }"
          />
        </div>
      </div>
      <div
        v-if="
          $v.mapping.source_data_structure_id.different === false ||
          $v.mapping.target_data_structure_id.different === false
        "
        class="form-group row mt-n3"
      >
        <label class="col-xl-3 col-lg-3 col-form-label"></label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <span class="text-danger">{{
            $t("mapping.useDifferentDataStructures")
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="card-footer d-flex align-items-center justify-content-end"
      @click="saveMapping"
    >
      <button class="btn btn-primary" :disabled="isBusy">
        {{ $t("mapping.mappingSave") }}
      </button>
    </div>
  </div>
</template>

<script>
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
const DataStructureService = new DataStructures();

import { required, minLength, sameAs, not } from "vuelidate/lib/validators";
import Mapping from "@/components/Projects/Mappings/mapping";

export default {
  components: {},
  props: {},
  data() {
    return {
      mapping: {
        label: "",
        description: "",
        active: true,
        source_data_structure_id: undefined,
        target_data_structure_id: undefined
      },
      dataStructures: [],
      isBusy: false
    };
  },
  validations() {
    return {
      mapping: {
        label: { required, minLength: minLength(3) },
        active: { required },
        source_data_structure_id: {
          required,
          minLength: minLength(1),
          different: not(sameAs("target_data_structure_id"))
        },
        target_data_structure_id: {
          required,
          minLength: minLength(1),
          different: not(sameAs("source_data_structure_id"))
        }
      }
    };
  },
  mounted() {
    this.loadDataStructures();
  },
  methods: {
    loadDataStructures() {
      this.isBusy = true;
      let params = {
        noPagination: true
      };
      DataStructureService.getAll(params)
        .then(response => {
          this.dataStructures = response.data.data;
          console.log(this.dataStructures);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    saveMapping() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isBusy = true;
      let data = this.mapping;
      Mapping.store(data)
        .then(response => {
          this.isBusy = false;
          this.$toast.fire({
            icon: "success",
            title: this.$t("mapping.mappingSaved")
          });
          this.$router.push({
            name: "projectMappingsMappingMapper",
            params: { id: response.data.data.id }
          });
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
