<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                {{ mapping.label }}
              </h3>
            </div>
          </div>
          <div class="card-body pt-4">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route, params: { type: item.type } }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: $route.params.type === item.type }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mapping.id" class="flex-row-fluid ml-lg-8">
        <div v-if="$route.name === 'projectMappingsDetails'">
          <BasicConfiguration
            :is-busy="isBusy"
            :mapping="mapping"
            @update="updateMapping"
            @back="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicConfiguration from "@/components/Projects/Mappings/Details/BasicConfiguration";
import Mapping from "@/components/Projects/Mappings/mapping";
const _ = require("lodash");

export default {
  components: { BasicConfiguration },
  props: {},
  data() {
    return {
      mapping: {},
      tabNavigation: [
        {
          route: "projectMappingsDetails",
          label: "mapping.detailsBasicConfiguration",
          icon: "fal fa-gear"
        }
      ],
      isBusy: false
    };
  },
  mounted() {
    this.loadMapping();
  },
  methods: {
    loadMapping() {
      this.isBusy = true;
      this.mapping = {};
      let mappingId = this.$route.params.id;
      Mapping.get(mappingId)
        .then(response => {
          this.mapping = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    updateMapping(data) {
      this.isBusy = true;
      const props = [
        "id",
        "label",
        "description",
        "active",
        "source_data_structure_id",
        "target_data_structure_id"
      ];
      const picks = _.pick(data, props);
      const requestData = Object.assign({}, this.mapping, picks);
      Mapping.update(requestData.id, requestData)
        .then(response => {
          this.mapping = response.data.data;
          this.$toast.fire({
            icon: "success",
            title: this.$t("mapping.mappingUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    back() {
      this.$router.push({ name: "projectMappings" });
    }
  }
};
</script>
