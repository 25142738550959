<template>
  <div>
    <div v-if="appVersion >= 2">
      <MappingTool v-if="showMapper" />
      <Create v-else-if="$route.name === 'projectMappingsCreate'" />
      <Details v-else-if="$route.name === 'projectMappingsDetails'" />
      <Table v-else />
    </div>
    <div v-if="selectedClient && appVersion < 2" :key="key">
      <MappingTool v-if="showMapper" />
      <Create v-else-if="$route.name === 'projectMappingsCreate'" />
      <Details v-else-if="$route.name === 'projectMappingsDetails'" />
      <Table v-else />
    </div>
    <div v-else-if="!selectedClient && appVersion < 2">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Projects/Mappings/Table";
import MappingTool from "@/components/Projects/Mappings/MappingTool";
import Create from "@/components/Projects/Mappings/Create";
import Details from "@/components/Projects/Mappings/Details";
import { mapGetters } from "vuex";

export default {
  components: { Create, Details, MappingTool, Table },
  props: [],
  data() {
    return {
      key: 1,
      mapperRoutes: [
        "projectMappingsMappingMapper",
        "projectMappingsMappingDebug"
      ]
    };
  },
  computed: {
    showMapper: function () {
      return this.mapperRoutes.includes(this.$route.name);
    },
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      this.key++;
    }
  },
  mounted() {},
  methods: {}
};
</script>
