<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ $t("mapping.detailsBasicConfiguration") }}
      </div>
      <div class="card-toolbar">
        <button class="btn btn-secondary ml-1" @click="back">
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="updateMapping">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingLabel") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.mappingData.label.$model"
            type="text"
            class="form-control"
            :class="[$v.mappingData.label.$error ? 'is-invalid' : 'is-valid']"
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingDescription") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <textarea v-model="mappingData.description" class="form-control" />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingActive") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <span class="switch">
            <label>
              <input v-model="$v.mappingData.active.$model" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingDataStructureSource") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            :value="mappingData.source"
            :items="sourceItems"
            :item-text="ds => `${ds.label} - v.${ds.version}`"
            item-value="id"
            :menu-props="{ offsetY: true }"
            class="form-control is-valid"
            disabled
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("mapping.mappingDataStructureTarget") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            :value="mappingData.target"
            :items="targetItems"
            :item-text="ds => `${ds.label} - v.${ds.version}`"
            item-value="id"
            :menu-props="{ offsetY: true }"
            class="form-control is-valid"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
const _ = require("lodash");

export default {
  components: {},
  props: {
    mapping: {
      type: Object,
      default: () => {}
    },
    isBusy: {
      type: Boolean
    }
  },
  data() {
    return {
      mappingData: _.cloneDeep(this.mapping)
    };
  },
  validations() {
    return {
      mappingData: {
        label: { required, minLength: minLength(3) },
        active: { required }
      }
    };
  },
  computed: {
    sourceItems: function () {
      let item = this.mappingData.source;
      return item ? [item] : [];
    },
    targetItems: function () {
      let item = this.mappingData.target;
      return item ? [item] : [];
    }
  },
  mounted() {},
  methods: {
    updateMapping() {
      this.$emit("update", this.mappingData);
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>
