<template>
  <div id="integrationsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("mapping.mappings") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createMapping">
          <i class="fal fa-plus"></i>
          {{ $t("mapping.mappingCreate") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadMappings">
        <b-table
          responsive
          :items="mappings"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <button
              v-b-tooltip.top.noninteractive="$t('mapping.edit')"
              class="btn btn-icon btn-light btn-sm"
              @click.left="openMapping(data.item)"
              @click.middle="openMapping(data.item)"
            >
              <i class="fal fa-paint-brush" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('mapping.configuration')"
              class="btn btn-icon btn-light btn-sm ml-1"
              @click.left="openDetails(data.item)"
              @click.middle="openDetails(data.item)"
            >
              <i class="fal fa-pen" />
            </button>
            <button
              v-b-tooltip.top.noninteractive="$t('mapping.delete')"
              class="btn btn-icon btn-light btn-sm ml-1"
              @click="deleteMapping(data.item)"
            >
              <i class="fal fa-trash" />
            </button>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Mapping from "@/components/Projects/Mappings/mapping";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  props: [],
  data() {
    return {
      mappings: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("table.description"),
          sortable: true
        },
        {
          key: "source",
          label: this.$t("mapping.dataStructureSource"),
          formatter: source => source.label,
          sortable: true
        },
        {
          key: "target",
          label: this.$t("mapping.dataStructureTarget"),
          formatter: target => target.label,
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          class: "text-right"
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadMappings();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectMappings"),
          route: ""
        },
        {
          title: this.$t("menu.projectsOverview"),
          route: "/project/mappings"
        }
      ]);
    },
    loadMappings() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Mapping.getAll(params, cancelToken)
        .then(response => {
          this.mappings = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    createMapping() {
      this.$router.push({ name: "projectMappingsCreate" });
    },
    openMapping(mapping) {
      this.$router.push({
        name: "projectMappingsMappingMapper",
        params: { id: mapping.id }
      });
    },
    openDetails(mapping) {
      this.$router.push({
        name: "projectMappingsDetails",
        params: { id: mapping.id }
      });
    },
    deleteMapping(mapping) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("mapping.deleteMappingTitle", {
            title: mapping.label
          }),
          text: this.$t("mapping.deleteMappingText"),
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          cancelButtonColor: "var(--secondary)"
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.isBusy = true;
          Mapping.delete(mapping.id)
            .then(response => {
              this.isBusy = false;
              if (response.data.data.success) {
                this.$toast.fire({
                  icon: "success",
                  title: this.$t("mapping.mappingDeleted")
                });
                this.loadMappings();
                return;
              }
              this.$swal.fire({
                icon: "error",
                title: this.$t("general.caution"),
                text: response.data.data.error
              });
            })
            .catch(error => {
              this.$swal.fire({
                icon: "error",
                title: this.$t("general.caution"),
                text: error.response?.data?.message
              });
              this.isBusy = false;
            });
        });
    }
  }
};
</script>
